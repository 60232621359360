<template>
	<div class="page-container">
		<div class="page-wrap">
			<div class="page-section">
				<div class="page-info">
					<div class="info-title">{{$t('liveplatform.liveEdit.pageTitle')}}</div>
					<div class="info-space">
						<div class="space-item">
							<el-tag effect="dark" size="mini">{{$t('liveplatform.liveEdit.status')}}</el-tag>
							<span class="item-tit">
								<span>{{liveInfo.title}}</span>
								<i class="el-icon-edit-outline" @click="dialogVisibleLive = true"></i>
							</span>
						</div>
					</div>
					<ul class="info-list">
						<li><label>{{$t('liveplatform.liveEdit.time')}}：</label>{{liveInfo.settime | parseTime()}}</li>
						<li><label>{{$t('liveplatform.liveEdit.column')}}：</label>{{liveInfo.tag_info?liveInfo.tag_info.tag_name:''}}</li>
						<li><label>{{$t('liveplatform.liveEdit.position')}}：</label>{{liveInfo.position?liveInfo.position:$t('liveplatform.marsText')}}</li>
					</ul>
				</div>
				<el-dialog
					:title="$t('liveplatform.liveEdit.dialogTitle1')"
					width="660px"
					:visible.sync="dialogVisibleLive">
					<el-form :model="liveForm" ref="liveForm">
						<div class="dialog-live">
							<div class="live-upload">
								<el-form-item prop="cover0" :rules="{ required: true, message: $t('liveplatform.validate.liveCover'), trigger: 'blur' }">
									<el-upload
										action="#"
										style="width: 90px;"
										:show-file-list="false"
										:http-request="httpRequestCover">
										<div class="page-upload">
											<template v-if="liveForm.cover0">
												<img :src="liveForm.cover0" alt="" width="100%">
												<div class="page-upload-tip">{{$t('liveplatform.liveEdit.reupload')}}</div>
											</template>
											<template v-else>
												<i class="el-icon-camera-solid"></i>
												<p>{{$t('liveplatform.liveEdit.upload')}}</p>
												<p><span>{{$t('liveplatform.liveEdit.coverTips')}}</span></p>
											</template>
										</div>
									</el-upload>
								</el-form-item>
								<el-form-item>
									<el-upload
										action="#"
										:show-file-list="false"
										v-loading="loadingUplaod"
										accept="video/*"
										:http-request="httpRequestVideo">
										<div class="page-upload">
											<template v-if="liveForm.pre_video_url">
												<video :src="liveForm.pre_video_url"></video>
												<div class="page-upload-tip">{{$t('liveplatform.liveEdit.reupload')}}</div>
											</template>
											<template v-else>
												<i class="el-icon-video-camera-solid"></i>
												<p>{{$t('liveplatform.liveEdit.uploadVideo')}}</p>
												<p><span>{{$t('liveplatform.liveEdit.videoTips')}}</span></p>
											</template>
										</div>
									</el-upload>
								</el-form-item>
							</div>
							<div class="live-main">
								<el-form-item prop="title" :rules="{ required: true, message: $t('liveplatform.validate.liveTitle'), trigger: 'blur' }">
									<el-input v-model="liveForm.title" :placeholder="$t('liveplatform.placeholder.liveTitle')" maxlength="10" show-word-limit></el-input>
								</el-form-item>
								<el-form-item>
									<el-input
										type="textarea"
										v-model="liveForm.intro"
										:rows="4"
										:placeholder="$t('liveplatform.placeholder.liveIntro')"
										maxlength="140"
										show-word-limit>
									</el-input>
								</el-form-item>
							</div>
						</div>
					</el-form>
					<div slot="footer" class="dialog-footer">
						<el-button type="danger" @click="handleSubmit" size="small" round>{{$t('liveplatform.liveEdit.dialogConfirm')}}</el-button>
					</div>
				</el-dialog>
				<el-dialog
					:title="$t('liveplatform.liveEdit.dialogTitle2')"
					width="800px"
					:visible.sync="dialogVisibleCropper">
					<div class="dialog-cropper">
						<vueCropper
							ref="cropper"
							:img="option.img"
							:outputSize="option.size"
							:outputType="option.outputType"
							:info="true"
							:full="option.full"
							:canMove="option.canMove"
							:canMoveBox="option.canMoveBox"
							:original="option.original"
							:autoCrop="option.autoCrop"
							:autoCropWidth="option.autoCropWidth"
							:autoCropHeight="option.autoCropHeight"
							:fixed="option.fixed"
							:fixedNumber="option.fixedNumber"
							:centerBox="option.centerBox"
							:infoTrue="option.infoTrue"
							:fixedBox="option.fixedBox">
						</vueCropper>
					</div>
					<div slot="footer" class="dialog-footer">
						<el-button @click="handleCancelCropper" size="small" round>{{$t('liveplatform.liveEdit.dialogCancel')}}</el-button>
						<el-button type="danger" @click="handleConfirmCropper" size="small" round v-loading="loadingUplaod">{{$t('liveplatform.liveEdit.dialogConfirm')}}</el-button>
					</div>
				</el-dialog>
			</div>
			<div class="page-section"></div>
		</div>
		<!-- <div class="page-bar"></div> -->
	</div>
</template>

<script>
	import { fetchLive, updateLive } from '@/api/liveplatform';
	import { normalOSSUpload } from '@/utils/upload';
	export default {
		data() {
			return {
				loadingUplaod: false,
				liveInfo: {},
				dialogVisibleLive: false,
				liveForm: {
					cover0: '',
					cover1: '',
					intro: '',
					live_id: '',
					pre_video_url: '',
					title: '',
				},
				dialogVisibleCropper: false,
				option: {
					img: '', // 裁剪图片的地址
					info: true, // 裁剪框的大小信息
					outputSize: 0.8, // 裁剪生成图片的质量
					outputType: 'jpeg', // 裁剪生成图片的格式
					canScale: false, // 图片是否允许滚轮缩放
					autoCrop: true, // 是否默认生成截图框
					autoCropWidth: 375, // 默认生成截图框宽度
					autoCropHeight: 375, // 默认生成截图框高度
					fixedBox: true, // 固定截图框大小 不允许改变
					fixed: true, // 是否开启截图框宽高固定比例
					fixedNumber: [1, 1], // 截图框的宽高比例
					full: true, // 是否输出原图比例的截图
					canMoveBox: false, // 截图框能否拖动
					original: true, // 上传图片按照原始比例渲染
					centerBox: true, // 截图框是否被限制在图片里面
					infoTrue: true, // true 为展示真实输出图片宽高 false 展示看到的截图框宽高
				},
				fileInfo: {},
			}
		},
		created() {
			this.getLiveInfo();
		},
		methods:{
			getLiveInfo(){
				let param = {
					live_id: this.$route.params.id,
          app_key: this.$store.getters.appKey,
					uuid: this.$store.getters.uuid
				}
				fetchLive(param).then(response => {
					const { data } = response;
					this.liveInfo = data;
					this.liveForm = {
						cover0: data.cover0,
						cover1: data.cover1,
						intro: data.intro,
						live_id: data.live_id,
						pre_video_url: data.pre_video_url,
						title: data.title,
					};
				}).catch(() => {
					this.$router.push({name: 'LiveList'});
				})
			},
			httpRequestCover(data){
				let that = this;
				const istype = ['image/jpeg','image/png'].includes(data.file.type);
				const isSize = data.file.size / 1024 / 1024 < 2;
				if (!istype) {
					that.$message.error(this.$t('liveplatform.errorMsg.imgFileType'));
					return false;
				}
				if (!isSize) {
					that.$message.error(this.$t('liveplatform.errorMsg.imgFileSize2M'));
					return false;
				}
				let imgUrl = URL.createObjectURL(data.file);
				this.fileInfo = data.file;
				this.$nextTick(() => {
					that.option.img = imgUrl;
					that.dialogVisibleCropper = true;
				})
			},
			handleCancelCropper(){
				this.dialogVisibleCropper = false;
			},
			handleConfirmCropper(){
				this.loadingUplaod = true;
				this.$refs.cropper.getCropBlob(async(file) =>{
					file.name = this.fileInfo.name;
					const { code, data } = await normalOSSUpload(file,'live');
					if(code==200){
						this.liveForm.cover0 = data.url;
						this.dialogVisibleCropper = false;
					}else{
						this.$message.error(this.$t('liveplatform.errorMsg.upload'))
					}
					this.loadingUplaod = false;
				})
			},
			async httpRequestVideo(value){
				const { file } = value;
				const istype = file.type.indexOf('video');
				const isSize = file.size / 1024 / 1024 < 20;
				if (istype<0) {
					this.$message.error(this.$t('liveplatform.errorMsg.videoFileType'));
					return false;
				}
				if (!isSize) {
					this.$message.error(this.$t('liveplatform.errorMsg.videoFileSize20M'));
					return false;
				}
				this.loadingUplaod = true;
				const { code, data } = await normalOSSUpload(file,'video');
				if(code==200){
					this.liveForm.pre_video_url = data.url;
				}else{
					this.$message.error(this.$t('liveplatform.errorMsg.upload'))
				}
				this.loadingUplaod = false;
			},
			handleSubmit(){
				let that = this;
				this.$refs.liveForm.validate((valid)=>{
					if(valid){
						let param = {
							...this.liveForm,
							app_key: this.$store.getters.appKey,
							uuid: this.$store.getters.uuid
						}
						updateLive(param).then(() => {
							this.getLiveInfo();
							this.dialogVisibleLive = false;
							this.$message.success(this.$t('liveplatform.successMsg.save'));
						})
					}else {
						return false;
					}
				})
			},
		}
	}
</script>

<style lang="scss" scoped>
	.page-container {
		display: flex;
		::v-deep .el-dialog {
			background-color: $--live-background-default;
			.el-dialog__header {
				padding: 16px 20px;
				border-bottom: 1px solid $--live-gray-white-4;
				.el-dialog__title {
					color: #FFFFFF;
					font-size: 16px;
				}
				.el-dialog__headerbtn {
					.el-dialog__close {
						color: $--live-link-color-4;
					}
					&:hover {
						.el-dialog__close {
							color: #FFFFFF;
						}
					}
				}
			}
			.el-dialog__body {
				padding: 20px;
			}
			.el-dialog__footer {
				border-top: 1px solid $--live-gray-white-4;
				padding: 10px 20px;
			}
		}
		::v-deep .el-input {
			.el-input__inner {
				color: #FFFFFF;
				border-radius: 6px;
				border-color: transparent;
				background-color: $--live-input-background;
			}
			.el-input__inner:hover,.el-input__inner:focus {
				border-color: $--live-input-hover-2;
			}
			.el-input__count {
				.el-input__count-inner {
					color: $--live-text-color-2;
					line-height: normal;
					background-color: $--live-input-background;
				}
			}
		}
		::v-deep .el-textarea {
			.el-textarea__inner {
				color: #FFFFFF;
				border-radius: 6px;
				border-color: transparent;
				background-color: $--live-input-background;
				resize: none;
			}
			.el-textarea__inner:hover,.el-textarea__inner:focus {
				border-color: $--live-input-hover-2;
			}
			.el-input__count {
				color: $--live-text-color-2;
				line-height: normal;
				background-color: $--live-input-background;
			}
		}
		.page-wrap {
			flex: 1;
			display: block;
			min-width: 500px;
			.page-section {
				border-radius: 6px;
				background-color: $--live-background-default;
			}
		}
		.page-bar {
			height: calc((100vh - 77px) - 24px);
			width: 780px;
			margin-left: 10px;
			border-radius: 6px;
			padding: 8px 20px 20px;
			background-color: $--live-background-default;
			box-sizing: border-box;
		}
		.page-info {
			padding: 20px;
			.info-title {
				color: #FFFFFF;
				font-size: 16px;
				line-height: 24px;
			}
			.info-space {
				display: flex;
				height: 60px;
				justify-content: space-between;
				align-items: flex-end;
				.space-item {
					display: flex;
					.item-tit {
						color: #FFFFFF;
						font-size: 16px;
						display: inline-block;
						margin-left: 8px;
						i {
							cursor: pointer;
							font-size: 18px;
							margin-left: 10px;
						}
					}
					.item-num {
						color: #FFFFFF;
						font-size: 30px;
						line-height: 1em;
						font-weight: 700;
						margin-bottom: 5px;
					}
					.item-text {
						color: $--live-text-color-2;
						font-size: 14px;
						line-height: 22px;
					}
				}
			}
			.info-list {
				li {
					color: $--live-text-color-2;
					font-size: 14px;
					margin-top: 20px;
					line-height: 22px;
					label {
						color: #FFFFFF;
					}
				}
			}
		}
		.dialog-live {
			padding: 20px;
			display: flex;
			border-radius: 6px;
			background-color: $--live-background-color;
			.live-upload {
				display: block;
				::v-deep .el-form-item {
					width: 90px;
					display: inline-block;
					vertical-align: top;
					margin-right: 8px;
					margin-bottom: 0;
				}
				.page-upload {
					width: 90px;
					height: 90px;
					display: flex;
					color: $--live-text-color-2;
					position: relative;
					align-items: center;
					justify-content: center;
					flex-direction: column;
					border-radius: 6px;
					overflow: hidden;
					cursor: pointer;
					background-color: $--live-gray-white-6;
					img {
						width: 100%;
						height: 100%;
						object-fit: cover;
					}
					video {
						width: 100%;
						height: 100%;
					}
					p {
						color: #FFFFFF;
						font-size: 12px;
						line-height: 18px;
						margin-top: 2px;
						span {
							color: $--live-text-color-2;
						}
					}
					i {
						font-size: 30px;
					}
					.page-upload-tip {
						left: 0;
						bottom: 0;
						color: #FFFFFF;
						font-size: 12px;
						padding: 3px 0;
						line-height: 18px;
						width: 100%;
						text-align: center;
						position: absolute;
						background-color: rgba(0,0,0,.8);
					}
				}
				
			}
			.live-main {
				flex: 1;
				width: 0;
				margin-left: 20px;
			}
		}
		.dialog-cropper {
			height: 500px;
		}
		.dialog-footer {
			.el-button--default {
				color: #FFFFFF;
				border-color: $--live-link-color-3;
				background-color: transparent;
				&:hover,&:focus {
					color: $--live-button-default-hover-font;
					border-color: $--live-button-default-hover-border;
				}
			}
			.el-button--danger {
				color: #FFFFFF;
				border-color: $--live-button-primary-border;
				background-color: $--live-button-primary-background;
				&:hover,&:focus{
					border-color: $--live-button-primary-hover-border;
					background-color: $--live-button-primary-hover-background;
				}
			}
		}
	}
</style>
